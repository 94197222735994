import { css } from '@emotion/core';

import { default as regularWoff } from './sourcesanspro-regular.woff';
import { default as regularWoff2 } from './sourcesanspro-regular.woff2';
import { default as boldWoff } from './sourcesanspro-bold.woff';
import { default as boldWoff2 } from './sourcesanspro-bold.woff2';
import { default as blackWoff } from './sourcesanspro-black.woff';
import { default as blackWoff2 } from './sourcesanspro-black.woff2';
import { default as headingRegularWoff } from './rubik-regular.woff';
import { default as headingRegularWoff2 } from './rubik-regular.woff2';
import { default as headingBoldWoff } from './rubik-bold.woff';
import { default as headingBoldWoff2 } from './rubik-bold.woff2';
import { default as monoRegularWoff } from './firamono-regular.woff';
import { default as monoRegularWoff2 } from './firamono-regular.woff2';
import { default as monoBoldWoff } from './firamono-bold.woff';
import { default as monoBoldWoff2 } from './firamono-bold.woff2';

export { default as regularWoff } from './sourcesanspro-regular.woff';

const background = '#2c2c2d';
const consoleWhite = '#f9fafc';
const computerBlack = '#18181a';

// prettier-ignore
const brandPrimary = '#f50049';
const brandPrimaryHover = '#f85c8b';
const brandPrimaryActive = '#9c002f';
const brandSecondary = '#26acff';
const opacities = [1, 0.85, 0.65, 0.45, 0.3, 0.15, 0.05].map(x =>
    ('00' + Math.trunc(255 * x).toString(16)).slice(-2)
  ),
  tints = opacities.map(a => consoleWhite + a),
  shades = opacities.map(a => computerBlack + a);

// prettier-ignore
const kingdomBlue = ["#ebf7ff", "#c3e8ff", "#9cd9ff", "#74caff", "#4dbbff", "#26acff", "#208dd1", "#196ea3", "#124f74", "#0b2f46", "#041018"];
// prettier-ignore
const plumberRed = ["#fdedec", "#facac6", "#f7a6a1", "#f4837b", "#f16056", "#ee3d31", "#c33229", "#982720", "#6d1c17", "#41110e", "#160605"];
// prettier-ignore
const bananaYellow = ["#fefbea", "#fcf5c1", "#faee98", "#f8e86f", "#f6e146", "#f5db1e", "#c9b419", "#9c8c14", "#70640e", "#433e09", "#171403"];
// prettier-ignore
const gooigiGreen = ["#f3f9ee", "#dbedcd", "#c3e1ab", "#abd68a", "#93ca69", "#7bbf48", "#659d3b",
  "#4f7a2e", "#385721", "#223514", "#0c1207"];

const theme = {
  /* - - - FONTS - - - */
  regularWoff,
  regularWoff2,
  boldWoff,
  boldWoff2,
  blackWoff,
  blackWoff2,
  headingRegularWoff,
  headingRegularWoff2,
  headingBoldWoff,
  headingBoldWoff2,
  monoRegularWoff,
  monoRegularWoff2,
  monoBoldWoff,
  monoBoldWoff2,

  /* - - - TYPOGRAPHY - - - */
  fontSizeSmall: [1.4, 1.6, 1.8, 2, 2.4, 3.2, 3.6],
  lineHeightSmall: [2, 2, 2.4, 3.2, 3.2, 4, 4.4],
  fontSizeMedium: [1.4, 1.6, 2, 2.4, 3.2, 3.6, 4],
  lineHeightMedium: [2, 2, 3, 3.2, 4, 4.8, 5.6],
  fontSizeLarge: [1.4, 1.6, 2, 2.4, 3.2, 4, 5.6],
  lineHeightLarge: [2, 2.4, 3, 3.2, 4, 5.6, 6.4],

  /* - - - LAYOUT - - - */
  columnWidth: 60,
  gutterWidth: 20,
  breakpointSmall: 320,
  breakpointMedium: 640,
  breakpointLarge: 960,
  breakpointFull: 1280,
  borderRadius: 0.4,

  /* - - - COLORS - - - */
  tints,
  shades,
  brandPrimary,
  brandPrimaryHover,
  brandPrimaryActive,
  brandSecondary,
  positiveColor: kingdomBlue[5],
  positiveHoverColor: kingdomBlue[3],
  positiveActiveColor: kingdomBlue[7],
  successColor: gooigiGreen[6],
  successHoverColor: gooigiGreen[4],
  successActiveColor: gooigiGreen[7],
  warningColor: bananaYellow[5],
  negativeColor: plumberRed[5],
  negativeHoverColor: plumberRed[3],
  negativeActiveColor: plumberRed[7],
  focusColor: '#8663a9',
  // base
  basePaletteBackground: background,
  basePaletteText: consoleWhite,
  // inverted
  invertedPaletteBackground: consoleWhite,
  invertedPaletteText: computerBlack,
  // info
  infoPaletteBackground: kingdomBlue[1],
  infoPaletteText: kingdomBlue[9],
  // success
  successPaletteBackground: gooigiGreen[3],
  successPaletteText: gooigiGreen[9],
  // warning
  warningPaletteBackground: bananaYellow[2],
  warningPaletteText: bananaYellow[9],
  // error
  errorPaletteBackground: plumberRed[1],
  errorPaletteText: plumberRed[9],

  // shadows
  softShadow: `0 0.2rem 0.4rem ${shades[5]}`,
  niceShadow: `0 0.2rem 0.8rem ${shades[2]}`,
  elevatedShadow: `0 0.4rem 1rem ${shades[0]}`
} as const;

export default theme;
export type Theme = typeof theme;

export enum Vars {
  // breakpoints
  BreakpointSmall = 'var(--breakpoint-small)',
  BreakpointMedium = 'var(--breakpoint-medium)',
  BreakpointLarge = 'var(--breakpoint-large)',
  BreakpointFull = 'var(--breakpoint-full)',
  CurrentWidth = 'var(--current-width)',
  // typography
  MainFont = 'var(--main-font), sans-serif',
  HeadingFont = 'var(--heading-font), sans-serif',
  CodeFont = 'var(--code-font), monospace',
  FontSizeTitle = 'var(--font-size-title)',
  LineHeightTitle = 'var(--line-height-title)',
  FontSizeH1 = 'var(--font-size-h1)',
  LineHeightH1 = 'var(--line-height-h1)',
  FontSizeH2 = 'var(--font-size-h2)',
  LineHeightH2 = 'var(--line-height-h2)',
  FontSizeH3 = 'var(--font-size-h3)',
  LineHeightH3 = 'var(--line-height-h3)',
  FontSizeH4 = 'var(--font-size-h4)',
  LineHeightH4 = 'var(--line-height-h4)',
  FontSizeBody = 'var(--font-size-body)',
  LineHeightBody = 'var(--line-height-body)',
  FontSizeInformation = 'var(--font-size-information)',
  LineHeightInformation = 'var(--line-height-information)',
  // colors
  BackgroundColor = 'var(--background-color)',
  TextColor = 'var(--text-color)',
  BrandPrimaryColor = 'var(--brand-primary-color)',
  BrandPrimaryHoverColor = 'var(--brand-primary-hover-color)',
  BrandPrimaryActiveColor = 'var(--brand-primary-active-color)',
  BrandSecondaryColor = 'var(--brand-secondary-color)',
  PositiveColor = 'var(--positive-color)',
  PositiveHoverColor = 'var(--positive-hover-color)',
  PositiveActiveColor = 'var(--positive-active-color)',
  NegativeColor = 'var(--negative-color)',
  NegativeHoverColor = 'var(--negative-hover-color)',
  NegativeActiveColor = 'var(--negative-active-color)',
  SuccessColor = 'var(--success-color)',
  SuccessHoverColor = 'var(--success-hover-color)',
  SuccessActiveColor = 'var(--success-active-color)',
  WarningColor = 'var(--warning-color)',
  FocusColor = 'var(--focus-color)',
  Tint100 = 'var(--tint-100)',
  Tint200 = 'var(--tint-200)',
  Tint300 = 'var(--tint-300)',
  Tint400 = 'var(--tint-400)',
  Tint500 = 'var(--tint-500)',
  Tint600 = 'var(--tint-600)',
  Tint700 = 'var(--tint-700)',
  Shade100 = 'var(--shade-100)',
  Shade200 = 'var(--shade-200)',
  Shade300 = 'var(--shade-300)',
  Shade400 = 'var(--shade-400)',
  Shade500 = 'var(--shade-500)',
  Shade600 = 'var(--shade-600)',
  Shade700 = 'var(--shade-700)',
  // styles
  BorderRadius = 'var(--border-radius)',
  SoftShadow = 'var(--soft-shadow)',
  NiceShadow = 'var(--nice-shadow)',
  ElevatedShadow = 'var(--elevated-shadow)'
}

type Palette = 'base' | 'inverted' | 'info' | 'success' | 'warning' | 'error';
export function palette(kind: Palette) {
  return css`
    --background-color: ${theme[
      (kind + 'PaletteBackground') as keyof typeof theme
    ] as string};
    --text-color: ${theme[
      (kind + 'PaletteText') as keyof typeof theme
    ] as string};
    --brand-primary-color: ${theme.brandPrimary};
    --brand-primary-hover-color: ${theme.brandPrimaryHover};
    --brand-primary-active-color: ${theme.brandPrimaryActive};
    --brand-secondary-color: ${theme.brandSecondary};
    --positive-color: ${theme.positiveColor};
    --positive-hover-color: ${theme.positiveHoverColor};
    --positive-active-color: ${theme.positiveActiveColor};
    --negative-color: ${theme.negativeColor};
    --negative-hover-color: ${theme.negativeHoverColor};
    --negative-active-color: ${theme.negativeActiveColor};
    --success-color: ${theme.successColor};
    --success-hover-color: ${theme.successHoverColor};
    --success-active-color: ${theme.successActiveColor};
    --warning-color: ${theme.warningColor};
    --focus-color: ${theme.focusColor};
    --tint-100: ${theme.tints[6]};
    --tint-200: ${theme.tints[5]};
    --tint-300: ${theme.tints[4]};
    --tint-400: ${theme.tints[3]};
    --tint-500: ${theme.tints[2]};
    --tint-600: ${theme.tints[1]};
    --tint-700: ${theme.tints[0]};
    --shade-100: ${theme.shades[6]};
    --shade-200: ${theme.shades[5]};
    --shade-300: ${theme.shades[4]};
    --shade-400: ${theme.shades[3]};
    --shade-500: ${theme.shades[2]};
    --shade-600: ${theme.shades[1]};
    --shade-700: ${theme.shades[0]};
    --soft-shadow: ${theme.softShadow};
    --nice-shadow: ${theme.niceShadow};
    --elevated-shadow: ${theme.elevatedShadow};

    background-color: ${Vars.BackgroundColor};
    color: ${Vars.TextColor};
  `;
}
export const media = {
  medium: `@media (min-width: ${theme.breakpointMedium}px)`,
  large: `@media (min-width: ${theme.breakpointLarge}px)`,
  full: `@media (min-width: ${theme.breakpointFull}px)`,
  hidpi: '@media (-webkit-min-device-pixel-ratio: 2)',
  mobileOnly: '@media (max-width: 48rem)'
};

export const columns = (cols: number) =>
  `${(theme.columnWidth * cols + theme.gutterWidth * (cols - 1)) / 10}rem`;
